/* footer */
footer {
    background-color: #2b2a29;
    font-size: 16px;
    color: #cacac9;
    padding: 80px 0 0 0;
    h5 {
        display: table;
        color: #fff;
        padding-bottom: 10px;
        border-bottom: 1px solid #807f7f;
        margin: 5px 0 20px;
    }
    a {
        color: #cacac9;
        &:focus {
            color: #cacac9;
        }
        &:hover {
            color: #fff;
        }
    }
    ul {
        @include ul-default;
        li {
            margin: 5px 0;
            &.active a {
                color: #fff;
            }
            &.col--phone,
            &.col--location,
            &.col--email{
                &:after {
                    color: #6b6a6a;
                }
            }
        }
    }
    .logo {
        display: block;
        margin-bottom: 15px;
    }
    .socials {
        margin-top: 30px;
        li {
            @include inline-block;
            margin: 0 10px 5px 0;
            a {
                display: flex;
                width: 30px;
                height: 30px;
                align-items: center;
                justify-content: center;
                background-color: #555554;
                font-size: 18px;
                .fa-paper-plane {
                    font-size: 14px;
                }
            }
        }
    }
    .footer__cts {
        a {
            display: table;
        }
        ul li {
            margin-bottom: 17px;
        }
    }
    .footer--down {
        font-size: 14px;
        padding: 20px 15px;
        margin-top: 80px;
        border-top: 1px solid #807f7f;
        p, .artmedia {
            @include inline-block;
            margin: 0;
        }
        .artmedia {
            position: relative;
            padding-right: 130px;
            float: right;
            img {
                position: absolute;
                right: 0;
                top: -3px;
            }
        }
    }
    @media screen and (max-width: 1199px) {
        padding-top: 50px;
        .footer--down {
            margin-top: 50px;
        }
    }
    @media screen and (max-width: 991px) {
        padding-top: 30px;
        .footer--down {
            margin-top: 30px;
        }
        .col-md-3 {
            margin-bottom: 15px;
        }
    }
    @media screen and (max-width: 768px) {
        .footer__nav {
            display: none;
        }
        .footer--down {
            margin-top: 10px;
        }
    }
    @media screen and (max-width: 600px) {
        .footer--down {
            padding: 15px 15px;
            p {
                display: block;
                text-align: center;
            }
            .artmedia {
                display: table;
                float: none;
                margin: 10px auto 0;
            }
        }
    }
}
/* footer */