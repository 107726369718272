/* Connection Font Icons */
@font-face {
    font-family: 'FontAwesome';
    src: url('../fonts/fontawesome-webfont.eot');
    src: url('../fonts/fontawesome-webfont.eot') format('embedded-opentype'), 
    url('../fonts/fontawesome-webfont.woff2') format('woff2'), 
    url('../fonts/fontawesome-webfont.woff') format('woff'), 
    url('../fonts/fontawesome-webfont.ttf') format('truetype'), 
    url('../fonts/fontawesome-webfont.svg?v=4.7.0#fontawesomeregular') format('svg');
    font-weight: 400;
    font-style: normal
}
/* Connection Font Icons */

@font-face {
    font-family: 'compact';
    src: url('../fonts/compact.eot');
    src: url('../fonts/compact.eot') format('embedded-opentype'),
         url('../fonts/compact.woff2') format('woff2'),
         url('../fonts/compact.woff') format('woff'),
         url('../fonts/compact.ttf') format('truetype'),
         url('../fonts/compact.svg#compact') format('svg');
}

@font-face {
    font-family: 'OpenSansSemibold';
    src: url('../fonts/OpenSansSemibold.eot');
    src: url('../fonts/OpenSansSemibold.eot') format('embedded-opentype'),
         url('../fonts/OpenSansSemibold.woff2') format('woff2'),
         url('../fonts/OpenSansSemibold.woff') format('woff'),
         url('../fonts/OpenSansSemibold.ttf') format('truetype'),
         url('../fonts/OpenSansSemibold.svg#OpenSansSemibold') format('svg');
}
@font-face {
    font-family: 'OpenSansItalic';
    src: url('../fonts/OpenSansItalic.eot');
    src: url('../fonts/OpenSansItalic.eot') format('embedded-opentype'),
         url('../fonts/OpenSansItalic.woff2') format('woff2'),
         url('../fonts/OpenSansItalic.woff') format('woff'),
         url('../fonts/OpenSansItalic.ttf') format('truetype'),
         url('../fonts/OpenSansItalic.svg#OpenSansItalic') format('svg');
}
@font-face {
    font-family: 'OpenSansLight';
    src: url('../fonts/OpenSansLight.eot');
    src: url('../fonts/OpenSansLight.eot') format('embedded-opentype'),
         url('../fonts/OpenSansLight.woff2') format('woff2'),
         url('../fonts/OpenSansLight.woff') format('woff'),
         url('../fonts/OpenSansLight.ttf') format('truetype'),
         url('../fonts/OpenSansLight.svg#OpenSansLight') format('svg');
}
@font-face {
    font-family: 'opensansbold';
    src: url('../fonts/opensansbold.eot');
    src: url('../fonts/opensansbold.eot') format('embedded-opentype'),
         url('../fonts/opensansbold.woff2') format('woff2'),
         url('../fonts/opensansbold.woff') format('woff'),
         url('../fonts/opensansbold.ttf') format('truetype'),
         url('../fonts/opensansbold.svg#opensansbold') format('svg');
}
@font-face {
    font-family: 'opensans';
    src: url('../fonts/opensans.eot');
    src: url('../fonts/opensans.eot') format('embedded-opentype'),
         url('../fonts/opensans.woff2') format('woff2'),
         url('../fonts/opensans.woff') format('woff'),
         url('../fonts/opensans.ttf') format('truetype'),
         url('../fonts/opensans.svg#opensans') format('svg');
}


$regular: 'opensans';
$bold: 'opensansbold';
$light: 'OpenSansLight';
$italic: 'OpenSansItalic';
$boldS: 'OpenSansSemibold';
$compact: 'compact';

@mixin inline-block {
	display: inline-block;
	vertical-align: top;
}
@mixin ul-default {
	list-style: none;
	margin: 0;
	padding: 0;
}

*:after, *:before {
	display: block;
	line-height: 1;
}

* {
    box-sizing: border-box;
}

body {
	display: flex;
	height: 100vh;
    min-width: 300px;
	position: relative;
	flex-direction: column;
	font-family: $regular;
    background-color: #e3e3e3;
    color: #414141;
    padding-top: 177px;
	margin: 0;
    &.load {
        a {
            transition: 350ms;
        }
    }
    @media screen and (max-width: 991px) {
        padding-top: 80px;
    }
}

h1 {
    position: relative;
	font-family: $compact;
	font-size: 42px;
	text-transform: uppercase;
	color: #414141;
	line-height: 1;
    padding-top: 15px;
	margin: 0 0 40px;
    &:after {
        content: '';
        height: 2px;
        width: 200vh;
        background-color: #009846;
        position: absolute;
        top: 0;
        right: calc(100% - 30px);
    }
    @media screen and (max-width: 1199px) {
        font-size: 36px;
    }
	@media screen and (max-width: 991px) {
		font-size: 30px;
        margin-bottom: 30px;
	}
	@media screen and (max-width: 768px) {
		font-size: 26px;
        margin-bottom: 20px;
	}
}
h2 {
    position: relative;
	font-family: $compact;
	font-size: 38px;
	color: #414141;
    text-transform: uppercase;
    padding-top: 15px;
    margin: 0 0 40px;
    &:after {
        content: '';
        height: 2px;
        width: 200vh;
        background-color: #009846;
        position: absolute;
        top: 0;
        right: calc(100% - 30px);
    }
    @media screen and (max-width: 1199px) {
        font-size: 32px;
    }
	@media screen and (max-width: 991px) {
		font-size: 28px;
		margin-bottom: 30px;
	}
	@media screen and (max-width: 768px) {
		font-size: 24px;
        margin-bottom: 20px;
	}
}
h3 {
	font-family: $compact;
	font-size: 27px;
	color: #414141;
    text-transform: uppercase;
    @media screen and (max-width: 1199px) {
        font-size: 24px;
    }
	@media screen and (max-width: 991px) {
		font-size: 22px;
	}
    @media screen and (max-width: 768px) {
        font-size: 20px;
    }
}
h4 {
	font-family: $bold;
	font-size: 22px;
	color: #414141;
    text-transform: uppercase;
}
h5 {
	font-family: $bold;
	font-size: 18px;
	color: #414141;
    text-transform: uppercase;
}
h6 {
	font-family: $bold;
	font-size: 16px;
	text-transform: uppercase;
	color: #414141;
    text-transform: uppercase;
}
a {
	text-decoration: none;
    color: #414141;
	&:focus {
		color: #414141;
		text-decoration: none;
	}
	&:hover {
		text-decoration: none;
        color: #009846;
	}
}
textarea, 
button, 
select, 
input[type="text"], 
input[type="email"], 
input[type="search"],
input[type="password"] {
	border-radius: 0px;
	-webkit-appearance: none;
}

.main, .page {
	position: relative;
	flex: 1 0 auto;
    font-size: 16px;
    overflow: hidden;
    line-height: 1.2;
	textarea, 
    input[type="text"], 
    input[type="email"], 
    input[type="search"], 
    input[type="password"] {
        min-height: 45px;
        width: 100%;
		background-color: #fff;
		font-family: $regular;
		font-size: 16px;
        color: #414141;
		padding: 11px 15px 10px;
        border: 1px solid #d5d4d4;
        line-height: 1;
        margin-bottom: 15px;
        &::placeholder {
            color: #959494;
        }
	}
    @media screen and (max-width: 991px) {
        textarea, 
        input[type="text"], 
        input[type="email"], 
        input[type="search"], 
        input[type="password"] { 
            min-height: 0px;
            padding: 10px 15px 9px;
        }
    }
    @media screen and (max-width: 768px) {
        textarea, 
        input[type="text"], 
        input[type="email"], 
        input[type="search"], 
        input[type="password"] {
            &::placeholder {
                padding-top: 2px;
            }
        }
    }
}
.main {
	.container {
        position: relative;
		padding-top: 80px;
		padding-bottom: 80px;
		@media screen and (max-width: 1170px) {
			padding-top: 25px;
			padding-bottom: 25px;
		}
		@media screen and (max-width: 768px) {
			padding-top: 20px;
			padding-bottom: 20px;
		}
	}
}
.btn {
	display: table;
    height: 45px;
	width: auto!important;
	background-color: #009846!important;
	font-family: $regular;
	font-size: 16px;
    text-transform: uppercase;
	color: #fff!important;
	cursor: pointer;
	border-radius: 0px;
	border: 2px solid #009846;
	padding: 12px 35px 11px!important;
    line-height: 1;
	transition: 300ms;
	&:focus, &:active {
		background-color: #009846!important;
		color: #fff;
	}
	&:hover, &.active {
		background-color: #fff!important;
		color: #009846!important;
	}	
    &.add {
        position: relative;
        padding-right: 60px;
        &:after {
            content: '\f07a';
            font-family: 'fontAwesome';
            font-size: 24px;
            color: #ffffff;
            position: absolute;
            top: 50%;
            right: 30px;
            transform: translateY(-50%);
            transition: 300ms;
        }
        &:hover {
            &:after {
                color: #009846;
            }
        }
    }
    @media screen and (max-width: 991px) {
        height: auto;
        padding: 10px 20px 9px!important;
        &.add {
            padding-right: 40px!important;
            &:after {
                right: 15px;
            }
        }
    }
}

.check--list {
	@include ul-default;
	li {
		display: block;
		position: relative;
		font-size: 16px;
		padding-left: 25px;
		margin: 5px 0;
		&:before {
			content: '\f00c';
			position: absolute;
			font-family: 'fontAwesome';
			font-size: 18px;
			color: #009846;
			left: 0;
			top: 0px;
		}
	}
}

.square--list {
    @extend .check--list;
    li {
        padding-left: 14px;
        margin: 5px 0;
        &:before {
            content: '';
            width: 5px;
            height: 5px;
            background-color: #009846;
            top: 7px;
        }
    }
    .square--list {
        margin-left: 15px;
        margin-top: 14px;
        li {
            padding-left: 20px;
            &:before {
                background-color: #2b2a29;
            }
        }
    }
    ul {
        padding: 0;
    }
}

.square--br--list {
    @extend .check--list;
    margin: 10px 0;
    li {
        margin-bottom: 3px;
        &:before {
            content: '';
            width: 6px;
            height: 6px;
            background-color: transparent;
            border: 1px solid #009846;
        }
    }
}

.col--location,
.col--phone,
.col--email,
.col--time {
	position: relative;
	padding-left: 25px;
	font-size: 16px;
	&:after {
		content: '';
		font-family: 'fontAwesome';
		font-size: 16px;
		color: #009846;
		position: absolute;
		top: 0px;
		left: 0;
	}
}
.col--location:after {
	content: '\f041';
}
.col--phone:after {
	content: '\f095';
    top: 2px;
}
.col--email {
    &:after {
        content: '\f0e0';
        font-size: 14px;
        top: 3px;
    }
    a {
        text-decoration: underline;
        &:hover {
            text-decoration: none;
        }
    }
}
.col--time:after {
    content: '\f017';
    top: 1px;
}

.green {
    color: #009846;
}

.bg--white {
    background-color: #fff;
}

.close {
    display: block;
    width: 12px;
    height: 12px;
    position: relative;
    opacity: 1;
    outline: none;
    &:before, &:after {
        content: '';
        width: 100%;
        height: 2px;
        background-color: #009846;
        position: absolute;
        top: 50%;
        left: 0;
        right: 0;
        margin: -1px auto 0;
        transition: 300ms;
    }
    &:before {
        transform: rotate(45deg);
    }
    &:after {
        transform: rotate(-45deg);
    }
    &:hover {
        opacity: 1;
        &:before {
            transform: rotate(135deg);
        }
        &:after {
            transform: rotate(45deg);
        }
    }
}

/* header */
header {
    height: 177px;
    background-color: #ffffff;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    box-shadow: 0px 0px 5px rgba(0,0,0,0.3);
    line-height: 1;
    z-index: 9;
    .up {
        min-height: 35px;
        background-color: #e3e3e3;
        padding: 10px 0 0 0;
        box-sizing: border-box;
    }
    .down {
        border-top: 1px solid #bdbdbd;
    }
    ul {
        @include ul-default;
    }
    > .container {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
    }

	/* header phones */
	.phones {
        max-height: 16px;
        background-color: #e3e3e3;
        position: absolute;
        left: 175px;
        top: 0;
        padding-left: 20px;
        padding-right: 20px;
        overflow: hidden;
        transition: 400ms;
        z-index: 1;
        &:before {
            content: '\f095';
            font-family: 'fontAwesome';
            font-size: 14px;
            color: #009846;
            position: absolute;
            top: 0;
            left: 2px;    
        }
        &:after {
            content: '\f078';
            font-family: 'fontAwesome';
            font-size: 12px;
            color: #009846;
            position: absolute;
            right: 2px;
            top: 0;
            transition: 200ms;
        }
        li {
            margin-bottom: 5px;
            a {
                font-size: 14px;
            }
        }
        &:hover {
            max-height: 200px;
            &:after {
                transform: rotate(-180deg);
            }
        }
    }
	/* header phones */

	/* header logo */
	.logo {
        margin: 10px 0;
        img {
            display: block;
        }
    }
	/* header logo */

    /* header slogan */
    .slogan {
        font-family: $compact;
        font-size: 24px;
        text-transform: uppercase;
        margin-left: 35px;
    }
    /* header slogan */

    /* header cart */
    .cart {
        font-size: 14px;
        position: absolute;
        right: 15px;
        top: 50%;
        padding-left: 40px;
        transform: translateY(-50%);
        &:before {
            content: '\f07a';
            font-family: 'fontAwesome';
            font-size: 24px;
            color: #414141;
            position: absolute;
            left: 0;
            top: -5px;
        }
        .cart__num {
            display: flex;
            width: 18px;
            height: 18px;
            align-items: center;
            justify-content: center;
            background-color: #009846;
            text-align: center;
            color: #fff;
            position: absolute;
            left: 15px;
            top: -12px;
            border-radius: 100%;
        }
    }
    /* header cart */

	/* header nav */
	.nav {
        @include inline-block;
        float: right;
        ul li {
            @include inline-block;
            margin: 0 0 0 16px;
            a {
                font-size: 14px;
            }
            &.active > a {
                color: #009846;
            }
        }
    }
	/* header nav */

    /* header catalog nav */
    .cat--nav {
        padding-right: 120px;
        > ul {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: space-between;
            > li {
                > a {
                    display: flex;
                    align-items: center;
                    height: 43px;
                    padding: 4px 0;
                    box-sizing: border-box;
                    img {
                        max-height: 100%;
                        object-fit: contain;
                        object-position: center center;
                    }
                    span {
                        display: none;
                    }
                }
                .cat--nav__sub {
                    display: none;
                    background-color: #f7f7f7;
                    position: absolute;
                    left: 15px;
                    right: 15px;
                    top: 42px;
                    padding: 50px;
                    &:after {
                        content: '';
                        height: 5px;
                        background: linear-gradient(to top, #f7f7f7, #ececec);
                        position: absolute;
                        top: 0;
                        left: 0;
                        right: 0; 
                    }
                    .cat--nav__sub--title {
                        display: table;
                        font-family: $bold;
                        font-size: 16px;
                        text-transform: uppercase;
                        color: #414141;
                        padding-bottom: 10px;
                        padding-right: 22px;
                        border-bottom: 2px solid #009846;
                        margin-bottom: 25px;
                    }
                    .cat--nav__sub--list {
                        display: flex;
                        flex-wrap: wrap;
                        > ul {
                            min-width: 32%;
                            max-width: 33.33%;
                            padding-right: 20px;
                            > li {
                                margin: 0 0 14px;
                                &.active > a {
                                    color: #009846;
                                }
                                ul > li {
                                    margin: 0 0 14px;
                                    &.active a {
                                        color: #009846;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .check--list li {
        &:before {
            top: 6px;
        }
    }
    /* header catalog nav */

	/* header langs */
	.langs {
        @include inline-block;
        li {
            @include inline-block;
            position: relative;
            margin: 0 18px 0 0;
            &:after {
                content: '/';
                position: absolute;
                right: -12px;
                top: 0;
            }
            &:last-child:after {
                display: none;
            }
            a {
                display: block;
                font-size: 14px;
                text-transform: uppercase;
            }
            &.active a {
                color: #009846;
                text-decoration: underline;
            }
        }
    }
	/* header langs */

    /* header search */
    .search {
        height: 42px;
        width: 50px;
        background-color: #fff;
        position: absolute;
        top: 0px; 
        right: 0px;
        padding-right: 35px;
        border: 1px solid transparent;
        box-sizing: border-box;
        transition: 300ms;
        input[type="search"] {
            width: 100%;
            border: none;
            padding: 11px 15px 10px;
            outline: none;
            box-shadow: none;
        }
        button {
            background-color: transparent;
            font-family: 'fontAwesome';
            font-size: 22px;
            padding: 0;
            border: none;
            position: absolute;
            right: 15px;
            top: 50%;
            transform: translateY(-50%);
            outline: none;
            &:after {
                content: '\f002';
            }
        }
        &:hover {
            width: 300px;
            border: 1px solid #bdbdbd;
            border-top: 1px solid transparent;
            border-bottom: 1px solid transparent;
        }
    }
    /* header search */
	@media screen and (max-width: 1199px) {
        .langs {
            display: none;
            text-align: center;
            background-color: rgba(0, 152, 70, 0.9);
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            padding: 15px;
            z-index: 2;
            li {
                &:after {
                    color: #fff;
                }
                a {
                    color: #fff;
                    &:hover {
                        color: #fff;
                    }
                }
                &.active a {
                    color: #fff;
                }
            }
        }
        .phones {
            display: none;
            max-height: 100%;
            background-color: rgba(0, 152, 70, 0.9);
            text-align: center;
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            padding: 10px 15px;
            transition: 0ms;
            z-index: 2;
            &:before {
                display: none;
            }
            li {
                margin: 5px 0;
                a {
                    font-size: 16px;
                    color: #fff;
                }
            }
        }
        .cat--nav {
            padding-right: 80px;
        }
        .phones--btn {
            display: block;
            font-family: 'fontAwesome';
            font-size: 20px;
            color: #009846;
            position: absolute;
            left: 50%;
            top: 7px;
            margin-left: -470px;
            &:after {
                content: '\f095';
            }
            &:hover {
                text-decoration: none;
            }
        }
        .langs--btn {
            display: block;
            position: absolute;
            font-family: 'fontAwesome';
            font-size: 20px;
            color: #009846;
            position: absolute;
            left: 50%;
            top: 7px;
            margin-left: -440px;
            &:after {
                content: '\f0ac'
            }
            &:hover {
                text-decoration: none;
            }
        }
        .cat--nav {
            display: block;
            > ul > li .cat--nav__sub {
                padding: 25px;
                .cat--nav__sub--title {
                    margin-bottom: 15px;
                }
            }
        }
    }
    @media screen and (min-width: 992px) {
        .container {
            position: relative;
        }
        .cat--nav {
            display: block!important;
        }
    }
    @media screen and (max-width: 991px) {
        height: 80px;
        border-bottom: 1px solid #009846;
        box-shadow: none;
        > .container {
            justify-content: center;
        }
        .down {
            border: none;
        }
        .logo {
            width: 100px;
            img {
                width: 100%;
                display: block;
            }
        }
        .up {
            min-height: 0px;
            background-color: transparent;
            padding: 0;
        }
        .slogan {
            font-size: 18px;
            margin-left: 10px;
        }
        .search {
            display: none;
            position: fixed;
            max-width: 100%;
            width: 100%;
            background-color: #fff;
            border: 1px solid #009846;
            transition: 0ms;
            overflow: hidden;
            z-index: 1;
            button {
                color: #009846;
            }
            &:hover {
                width: 100%;
                border: 1px solid #009846;
            }
        }
        .search--btn {
            display: block;
            font-family: 'fontAwesome';
            font-size: 20px;
            color: #009846;
            position: absolute;
            top: 10px;
            right: 15px;
            &:after {
                content: '\f002';
            }
        }
        .nav {
            display: none;
            max-height: calc(100% - 80px);
            background-color: #fff;
            position: fixed;
            top: 80px;
            left: 0;
            right: 0;
            border-top: 1px solid #009846;
            border-bottom: 1px solid #009846;
            overflow: auto;
            > ul {
                padding: 0 15px;
                margin: 0 auto;
                > li {
                    display: block;
                    margin: 10px 0;
                    > a {
                        font-size: 16px;
                    }   
                    &.hide {
                        display: block!important;
                    }
                }
            }
        }
        .nav--btn {
            width: 35px;
            height: 30px;
            position: absolute;
            left: 12px;
            top: 34px;
            padding: 0;
            margin: 0;
            outline: none;
            float: none;
            span {
                position: absolute;
                left: 0;
                right: 0;
                width: 85%;
                height: 3px;
                margin: 0 auto;
                background-color: #009846;
                transition: 350ms;
                &:nth-of-type(1) {
                    top: 7px;
                }
                &:nth-of-type(2) {
                    top: 14px;
                }
                &:nth-of-type(3) {
                    top: 21px;
                }
            }
            &.open {
                span {
                    &:nth-of-type(1) {
                        top: 14px;
                        transform: rotate(45deg);
                    }
                    &:nth-of-type(2) {
                        opacity: 0;
                    }
                    &:nth-of-type(3) {
                        top: 14px;
                        transform: rotate(-45deg);
                    }
                }
            }
        }
        .cat--nav {
            display: none;
            padding-right: 0;
            > ul {
                display: block;
                margin: 8px 0 0;
                > li {
                    display: block;
                    margin: 3px 0;
                    > a {
                        height: 35px;
                        padding: 0;
                        > span {
                            display: block;
                            padding-left: 10px;
                        }
                        > img {
                            width: 40px;
                        }
                    }
                    &.active > a {
                        color: #009846;
                    }
                    .cat--nav__sub {
                        position: relative;
                        top: 0;
                        left: 0;
                        right: 0;
                        padding: 20px 25px;
                        margin: 10px 0;
                        .cat--nav__sub--list {
                            width: 100%;
                            max-width: 100%;
                            min-width: 0px;
                            > ul {
                                width: 100%;
                                min-width: 0;
                                max-width: 100%;
                                padding-right: 0;
                                > li {
                                    margin-right: 10px;
                                }
                            }
                        }
                    }
                }
            }
        }
        .cart {
            top: 55px;
        }
        .phones--btn {
            left: 45px;
            margin: 0;
        }
        .langs--btn {
            left: 15px;
            margin: 0;
        }
    }
    @media screen and (max-width: 768px) {
        > .container {
            flex-direction: column;
        }
        .logo {
            width: 85px;
            margin: 5px 0;
        }
        .slogan {
            font-size: 14px;
            margin: 0;
        }
        .nav {
            > ul {
                width: 100%;
            }
        }
        
    }
    @media screen and (max-width: 620px) {
        .cart {
            width: 23px;
            height: 23px;
            right: 16px;
            top: 60px;
            padding: 0;
            .cart__sum {
                display: none;
            }
        }
    }
    @media screen and (max-width: 480px) {
        .slogan {
            font-size: 12px;
        }
        .cat--nav > ul > li .cat--nav__sub {
            padding: 15px 20px 10px;
            .cat--nav__sub--list > ul > li {
                display: block;
                margin-right: 0;
                margin-bottom: 10px;
            }
        }
        .langs--btn {
            left: 7px;
        }
        .phones--btn {
            left: 35px;
        }
        .nav--btn {
            left: 5px;
        }
    }
}
/* header */