/* main content */
.modal-open {
	overflow-y: visible;
	.modal {
		overflow: auto;
	}
}

.fancybox-enabled {
	overflow: visible;
	body {
		overflow-x: hidden;
	}
}

/* main slider */
.main__slider {
    position: relative;
    padding: 60px 0 90px;
    &:after {
        content: '';
        background-color: #fff;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 55%;
        right: 0;
        z-index: -1;
    }
    .socials {
        @include ul-default;
        height: 315px;
        text-align: center;
        position: absolute;
        left: 50%;
        top: 50%;
        margin-left: -715px;
        transform: translateY(-50%);
        line-height: 1;
        z-index: 1;
        &:after {
            content: '';
            width: 1px;
            background-color: #aaaaaa;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            margin: 0 auto;
            z-index: -1;
        }
        li {
            display: block;
            background-color: #e3e3e3;
            padding: 0 0 22px 0;
            a {
                font-size: 20px;
                .fa-paper-plane {
                    font-size: 18px;
                }
            }
        }
    }
    @media screen and (max-width: 1440px) {
        .socials {
            margin-left: -620px;
        }
    }
    @media screen and (max-width: 1280px) {
        .socials {
            margin: 0;
            left: 35px;
        }
    }
    @media screen and (max-width: 991px) {
        background-color: #fff;
        padding: 20px 0 20px;
        &:after {
            display: none;
        }
        .socials {
            height: auto;
            left: 30px;
            &:after {
                display: none;
            }
            li {
                background-color: transparent;
                padding: 10px 0;
            }
        }
    }
    @media screen and (max-width: 480px) {
        padding: 10px;
        .socials {
            left: 20px;
        }
    }
}
.slider {
    visibility: hidden;
	margin-bottom: 0px!important;
	.slick-slide {
        height: 587px;
		overflow: hidden;
		position: relative;
		outline: none;
        padding-bottom: 18px;
		.slider__block {
            width: 350px;
			position: absolute;
			left: 50%;
			top: 50%;
			color: #414141;
            margin-left: -565px;
            line-height: 1.3; 
            transform: translateY(-50%);
            .slider__block__title {
                display: block;
                font-family: $compact;
                font-size: 42px;
                text-transform: uppercase;
                margin-bottom: 40px;
                position: relative;
                &:after {
                    content: '';
                    height: 3px;
                    width: 68px;
                    background-color: #414141;
                    position: absolute;
                    left: 0;
                    bottom: -15px;
                }
                span {
                    display: block;
                    font-size: 50px;
                    color: #009846;
                }
            }
            .btn {
                margin-top: 30px;
            }
        }
        .slider__img {
            position: absolute;
            right: 40px;
            left: 50%;
            top: 0;
            bottom: 18px;
            margin-left: -175px;
            &:after {
                content: '';
                background-color: #d2d2d2;
                position: absolute;
                top: 60px;
                left: -18px;
                bottom: -18px;
                right: 90px;
                z-index: -1;
            }
            img {
                display: block;
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center center;
            }
        }
	}
	.slick-dots {
        width: 45%;
		bottom: -50px;
        left: auto;
        right: 0;
		li {
            width: auto;
            height: auto;
			margin: 0px 10px;
			button {
				width: 12px;
				height: 12px;
				background-color: #d2d2d2;
				padding: 0;
                transition: 300ms;
                &:before {
					display: none;
				}
				&:hover {
					background-color: #009846;
				}
			}
			&.slick-active {
				button {
					background-color: #009846;
				}
			}
		}
	}
    @media screen and (max-width: 1440px) {
        .slick-slide {
            height: 500px;
        }
    }
    @media screen and (max-width: 1366px) {
        .slick-slide {
            height: 450px;
        }
    }
    @media screen and (max-width: 1280px) {
        .slick-slide {
            .slider__img {
                right: 20px;
                left: 60%;
            }
            .slider__block {
                margin-left: -450px;
            }
        }
    }
    @media screen and (max-width: 991px) {
        .slick-slide {
            height: auto;
            padding: 0;
            .slider__block {
                width: auto;
                left: 70px;
                margin: 0;
                z-index: 1;
                .slider__block__title {
                    font-size: 32px;
                    margin-bottom: 30px;
                    span {
                        font-size: 40px;
                    }
                }
                .btn {
                    margin-top: 20px;
                }
            }
            .slider__img {
                height: 380px;
                width: auto;
                position: relative;
                right: 0;
                left: 0;
                margin: 0 20px;
                &:after {
                    background-color: #fff;
                    left: 0;
                    right: 0;
                    top: 0;
                    bottom: 0;
                    opacity: 0.7;
                    z-index: 0;
                }
            }
        }
        .slick-dots {
            width: auto;
            bottom: 20px;
            left: 0;
            li {
                button {
                    background-color: #009846;
                    opacity: 0.5;
                    &:hover {
                        opacity: 1;
                    }
                }
                &.slick-active {
                    button {
                        opacity: 1;
                    }
                }
            }
        }
    }
    @media screen and (max-width: 768px) {
        .slick-slide {
            .slider__block {
                font-size: 14px;
                .slider__block__title {
                    font-size: 24px;
                    margin-bottom: 20px;
                    span {
                        font-size: 30px;
                    }
                    &:after {
                        width: 50px;
                        bottom: -8px;
                    }
                }
            }
        }
    }
    @media screen and (max-width: 480px) {
        .slick-slide {
            .slider__img {
                margin: 0;
            }
            .slider__block {
                left: 45px;
                right: 10px;
            }
        }
    }
}
/* main slider */

/* main advantages */
.advantages {
    display: flex;
    width: 1140px;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 40px auto;
    .advantages__col {
        display: flex;
        height: 100px;
        width: calc(50% - 15px);
        flex-wrap: nowrap;
        align-items: center;
        background-color: #fff;
        padding: 10px 15px 10px 75px;
        img {
            width: 80px;
            height: 60px;
            object-fit: contain;
            object-position: center center;
        }
        p {
            margin: 0 0 0 25px;
        }
    }
    @media screen and (max-width: 1199px) {
        width: 100%;
        padding: 0 15px;
    }
    @media screen and (max-width: 991px) {
        .advantages__col {
            padding-left: 15px;
        }
    }
    @media screen and (max-width: 768px) {
        margin: 25px auto;
    }
    @media screen and (max-width: 620px) {
        margin: 15px 0;
        .advantages__col {
            width: 100%;
            margin: 15px 0;
            img {
                height: 60px;
                width: 60px;
            }
            p {
                margin-left: 15px;
            }
        }
    }
}
/* main advantages */

/* main popular goods */
.main {
    .cat {
        width: 1140px;
        margin: 0 auto;
    }
    @media screen and (max-width: 1199px) {
        .cat {
            width: 100%;
            padding: 0 15px;
        }
    }
    @media screen and (max-width: 991px) {
        .img {
            display: none;
        }
    }
}
.popular {
    position: relative;
    > img {
        position: absolute;
        left: -270px;
        top: 190px;
        z-index: -1;
    }
    @media screen and (max-width: 991px) {
        > img {
            display: none;
        }
    }
}
/* main popular goods */

/* main shares */
.shares {
    height: 265px;
    background-color: #fff;
    position: relative;
    margin: 80px 0;
    .shares__img {
        height: 100%;
        position: absolute;
        top: 0;
        &.left {
            left: 0;
        }
        &.right {
            right: 0;
        }
    }
    .shares__title {
        display: block;
        font-family: $compact;
        font-size: 30px;
        text-transform: uppercase;
        color: #2b2a29;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateY(-50%);
        line-height: 1.4;
        span {
            display: block;
            margin-left: 35px;
            &:last-of-type {
                margin-left: 70px;
            }
        }
    }
    @media screen and (max-width: 1780px) {
        .shares__img {
            &.left {
                left: -5%;
            }
            &.right {
                right: -7%;
            }
        }
    }
    @media screen and (max-width: 1580px) {
        .shares__img {
            &.left {
                left: -10%;
            }
        }
    }
    @media screen and (max-width: 1440px) {
        .shares__img {
            &.left {
                left: -15%;
            }
            &.right {
                right: -15%;
            }
        }
    }
    @media screen and (max-width: 1366px) {
        .shares__img {
            &.left {
                left: -20%;
            }
        }
    }
    @media screen and (max-width: 1199px) {
        height: auto;
        padding: 25px 15px;
        margin: 40px 0;
        .shares__title {
            text-align: center;
            position: relative;
            left: 0;
            right: 0;
            top: 0;
            transform: translateY(0);
            padding: 0 15px;
            margin: 0;
            span {
                margin-left: 0;
                &:last-of-type {
                    margin-left: 0;
                }
            }

        }
        .shares__img {
            display: none;
        }
    }
    @media screen and (max-width: 768px) {
        .shares__title {
            font-size: 24px;
        }
    }
}
/* main shares */

/* main novelty */
.novelty {
    position: relative;
    padding-bottom: 80px;
    > img {
        position: absolute;
        top: -175px;
        right: -270px;
        z-index: -1;
    }
    @media screen and (max-width: 1199px) {
        padding-bottom: 40px!important;
    }
    @media screen and (max-width: 991px) {
        > img {
            display: none;
        }
    }
}
/* main novelty */

/* main about */
.about {
    .container {
        z-index: 1;
    }
    h1 {
        display: table;
        &:after {
            width: 200vh;
            left: calc(100% - 30px);
            margin: 0;
        }
    }
    h3 {
        text-align: center;
        margin: 0 0 25px;
    }
    .img {
        position: absolute;
        z-index: -1;
        top: 20px;
        left: -255px;
    }
    .about__block {
        background-color: #fff;
    }
    @media screen and (max-width: 991px) {
        h3 {
            margin-top: 25px;
        }
    }
    @media screen and (max-width: 480px) {
        h1:after {
            display: none;
        }
    }
}
.about__block {
    border: 2px solid #efefef;
    position: relative;
    line-height: 1;
    &:before {
        content: '';
        height: 1px;
        background-color: #efefef;
        position: absolute;
        left: 30px;
        right: 30px;
        top: 50%;
        transform: translateY(-50%);
    }
    &:after {
        content: '';
        width: 1px;
        background-color: #efefef;
        position: absolute;
        left: 0;
        right: 0;
        top: 15px;
        bottom: 15px;
        margin: 0 auto;
    }
    ul {
        @include ul-default;
        display: flex;
        width: 100%;
        flex-wrap: wrap;
        li {
            display: flex;
            width: 50%;
            min-height: 120px;
            flex-direction: column;
            justify-content: center;
            font-size: 16px;
            text-align: center;
            padding: 10px;
            .num {
                font-family: 'compact';
                font-size: 36px;
                text-transform: uppercase;
                color: #009846;
            }
            p {
                margin: 0;
            }
        }
    }
}
/* main about */

/* main services */
.services {
    .container {
        padding-top: 0;
        z-index: 0;
    }
    .img {
        position: absolute;
        right: -250px;
        top: -170px;
        z-index: -1;
    }
}
.services__block {
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    margin: 0 -7px;
    .services__block--col {
        background-color: #efefef;
        width: calc(25% - 14px);
        padding: 40px 25px 60px;
        margin: 0 7px;
        h5 {
            min-height: 42px;
            text-align: center;
            line-height: 1.2;
            margin: 0 0 20px;
        }
    }
    @media screen and (max-width: 1199px) {
        .services__block--col {
            padding: 30px 15px 40px;
        }
    }
    @media screen and (max-width: 991px) {
        .services__block--col {
            width: calc(50% - 14px);
            margin-bottom: 14px;
        }
    }
    @media screen and (max-width: 768px) {
        .services__block--col {
            padding: 20px 15px;
            h5 {
                font-size: 16px;
            }
        }
    }
    @media screen and (max-width: 540px) {
        .services__block--col {
            width: 100%;
            h5 {
                min-height: 0px;
            }
        }
    }
}
/* main services */

/* main partners */
.partners {
    .container {
        padding-bottom: 0;
    }
    .img {
        position: absolute;
        z-index: -1;
        left: -250px;
        top: 75px;
    }
}
.partners--slider {
    background-color: #fff;
    padding: 0 45px;
    &.arrow {
        .slick-arrow {
            background-color: #e3e3e3;
            top: 50%;
            transform: translateY(-50%);
            &.slick-prev {
                left: -15px;
                right: auto;
            }
            &.slick-next {
                right: -15px;
            }
            &:hover {
                background-color: #fff;
            }
        }
    }
    .slick-slide {
        display: flex;
        height: 160px;
        outline: none;
        padding: 20px 0;
        align-items: center;
        margin: 0 25px;
        img {
            max-width: 100%;
        }
    }
    @media screen and (max-width: 991px) {
        .slick-slide {
            height: 120px;
            margin: 0 15px;
            img {
                max-height: 100%;
                object-fit: contain;
                object-position: center center;
                margin: 0 auto;
            }
        }
    }
    @media screen and (max-width: 768px) {
        &.arrow {
            .slick-arrow {
                background-color: transparent;
                box-shadow: none;
                &.slick-prev {
                    left: 0;
                }
                &.slick-next {
                    right: 0;
                }
            }
        }
    }
}
/* main partners */

/* main news */
.news {
    .container {
        padding-bottom: 100px;
    }
    .news__col {
        color: #414141;
        .news__col--img {
            height: 280px;
            overflow: hidden;
            img {
                display: block;
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center center;
                transition: 300ms;
            }
        }
        .news__col--block {
            padding: 30px;
            .date {
                display: block;
                font-size: 14px;
                color: #717171;
            }
            h5 {
                margin: 15px 0;
                transition: 300ms;
            }
        }
        &:hover {
            .news__col--img img {
                transform: scale(1.1);
            }
            .news__col--block h5 {
                color: #009846;
            }
        }
    }
    .img {
        position: absolute;
        bottom: 0;
        right: -260px;
        z-index: -1;
    }
    @media screen and (max-width: 1199px) {
        .container {
            padding-bottom: 65px;
        }
        .news__col {
            .news__col--img {
                height: 240px;
            }
        }
    }
    @media screen and (max-width: 991px) {
        .container {
            padding-bottom: 40px;
        }
        .news__col {
            .news__col--img {
                height: 180px;
            }
            .news__col--block {
                padding: 20px 0;
                h5 {
                    font-size: 16px;
                }
            }
        }
    }
    @media screen and (max-width: 767px) {
        .container {
            padding-bottom: 20px;
        }
        .news__col {
            margin-bottom: 25px;
            .news__col--img {
                height: auto;
            }
        }
    }
}
/* main news */

/* main question */
.question {
    background-color: #fff;
    .container {
        display: flex;
        min-height: 170px;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        padding: 35px 15px;
        &:after, &:before {
            display: none;
        }
    }
    .question__left {
        p {
            color: #7e7e7e;
            margin: 0;
        }
    }
    .question__right {
        width: 610px;
    }
    h3 {
        font-size: 24px;
        color: #1b1414;
        margin: 0 0 17px!important;
    }
    input[type="text"],
    input[type="email"] {
        width: 290px;
        margin-left: 12px;
        margin-bottom: 15px;
    }
    .g-recaptcha {
        @include inline-block;
        margin-left: 12px;
    }
    .btn {
        @include inline-block;
        float: right;
    }
    @media screen and (max-width: 991px) {
        .question__left,
        .question__right {
            width: 100%;
            br {
                display: none;
            }
        }
        .question__right {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
        }
        input[type="text"],
        input[type="email"] {
            width: 49%;
            margin: 15px 0;
        }  
        .g-recaptcha {
            margin-left: 0;
        }
        .btn {
            float: none;
        }
    }
    @media screen and (max-width: 490px) {
        .container {
            padding: 25px 15px;
        }
        .question__left p {
            margin-bottom: 15px;
        }
        .question__right {
            display: block;
        }
        input[type="text"],
        input[type="email"] {
            width: 100%;
            margin: 0 0 15px 0;
        }
        .g-recaptcha {
            float: right;
        }
        .btn {
            float: right;
            margin-top: 15px;
        }
    }
    @media screen and (max-width: 360px) {
        .g-recaptcha {
            transform: scale(0.9);
            transform-origin: 100% 50%;
        }
    }
}
/* main question */

/* main content */