/* page content */
.page {
    position: relative;
	.news1, .news {
		.date {
			color: #009846;
		}
		a {
			text-decoration: underline;
			&:hover {
				color: #009846;
				text-decoration: none;
			}
		}
	}
	.page_nav {
		.active {
			color: #009846;
		}
	}
    #ask-form {
        max-width: 350px;
    }
    .write {
        color: #009846;
        text-decoration: none;
        &:hover {
            text-decoration: underline;
        }
    }
    .back_link {
        margin: 25px 0;
    }
    .about__block {
        background-color: #fff;
        margin: 40px 0;
        &:after, &:before {
            display: none;
        }
        ul {
            li {
                width: 25%;
                position: relative;
                &:after {
                    content: '';
                    width: 1px;
                    background-color: #efefef;
                    position: absolute;
                    top: 24px;
                    bottom: 24px;
                    right: 0;
                }
                &:last-child:after {
                    display: none;
                }
            }
        }
    }
    h2 {
        margin-top: 65px;
    }
    h3 {
        margin: 0 0 25px 0;
    }
    > .img {
        position: absolute;
        top: 100px;
        left: 50%;
        margin-left: -835px;
        z-index: -1;
        &:nth-of-type(2) {
            top: 720px;
            left: auto;
            right: 50%;
            margin-right: -835px;
        }
        &:nth-of-type(3) {
            top: 1350px;
        }
    }
    .question {
        margin-top: 75px;
    }
	@media screen and (max-width: 1170px) {
		font-size: 16px;
		#photos-list td img {
			width: 100%;
			height: auto;
		}
	}
    @media screen and (max-width: 991px) {
        h2 {
            margin-top: 45px;
        }
        .question {
            margin-top: 40px;
        }
        > .img {
            display: none;
        }
    }
    @media screen and (max-width: 680px) {
        .about__block {
            ul li {
                width: 50%;
                &:after {
                    height: 1px;
                    width: auto;
                    left: 10px;
                    right: 10px;
                    top: auto;
                    bottom: 0;
                }
                &:nth-last-child(-n+2){
                    &:after {
                        display: none;
                    }
                }
            }
        }
    }
	@media screen and (max-width: 480px) {
		p img, p strong img {
			width: 100%!important;
			height: auto!important;
			display: block!important;
			float: none!important;
			margin: 0 0 10px 0!important;		
		}
		#photos-list td {
			width: 50%;
			@include inline-block;
		}
	}
}
/* breadcrumbs */
.breadcrumbs {
	font-size: 14px;
    margin: 30px 0 50px;
	ul {
		@include ul-default;
		li {
			padding: 5px 0px;
			color: #009846;
			@include inline-block;
			a {
				text-decoration: none;
                color: #414141;
			}
			&:after {
				content: '';
                width: 1px;
                height: 14px;
                background-color: #6c6c6c;
				margin: 0px 6px 0px 10px;
				color: #414141;
				@include inline-block;
			}
			&:last-of-type:after {
				display: none;
			}
		}
	}
    @media screen and (max-width: 1199px) {
        margin: 20px 0 30px;
    }
    @media screen and (max-width: 991px) {
        margin: 10px 0 20px;
    }
}
/* breadcrumbs */

/* page about */
.about__img {
    width: 555px;
    padding-bottom: 70px;
    position: relative;
    img {
        display: block;
        max-width: 100%;
        border: 8px solid #e3e3e3;
        &:nth-of-type(2) {
            position: absolute;
            right: -8px;
            top: 70px;
        }
    }
    @media screen and (max-width: 1199px) {
        width: 100%;
        margin-bottom: 15px;
        img {
            width: 55%;
        }
    }
    @media screen and (max-width: 768px) {
        padding-bottom: 40px;
        img {
            &:nth-of-type(2) {
                top: 40px;
            }
        }
    }
}
/* page about */

/* page catalog */
.cat {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    h1, h2, h3 {
        width: 100%;
    }
    .right {
        display: flex;
        flex-wrap: wrap;
        width: calc(100% - 260px);
        position: relative;
        .pagination {
            width: 100%;
            margin: 10px 0 30px 30px;
            &.up {
                margin: 0;
            }
        }
    }
    .cat__col {
        display: inline-flex;
        width: calc(33.33% - 30px);
        flex-direction: column;
        position: relative;
        background-color: #fff;
        font-size: 14px;
        color: #6a6969;
        padding: 15px 15px 25px;
        outline: none;
        margin: 0 0 30px 30px;
        .cat__col--img {
            height: 155px;
            margin-bottom: 25px;
            img {
                display: block;
                height: 100%;
                width: 100%;
                object-fit: contain;
                object-position: center center;
            }
        }
        h6 {
            font-size: 14px;
            margin: 0 0 10px;
            transition: 300ms;
        }
        p {
            min-height: 16px;
        }
        .article {
            min-height: 32px;
            padding-right: 0;
        }
        .price {
            font-family: $bold;
            font-size: 16px;
            color: #2b2a29;
        }
        .cart {
            display: flex;
            width: 40px;
            height: 40px;
            align-items: center;
            justify-content: center;
            background-color: #009846;
            font-family: 'fontAwesome';
            font-size: 24px;
            color: #fff;
            position: absolute;
            bottom: 30px;
            right: 15px;
            cursor: pointer;
            &:after {
                content: '\f07a';
            } 
        }
        &:hover {
            h6 {
                color: #009846;
            }
        }
    }
    &.cat--search {
        margin: 0 -15px;
        .cat__col {
            min-height: 375px;
            width: calc(25% - 30px);
            margin: 0 15px 30px;
        }
    }
    @media screen and (max-width: 1199px) {
        .cat__col {
            width: calc(50% - 30px);
            margin-left: 30px;
        }
        &.cat--search {
            .cat__col {
                width: calc(33.33% - 30px);
            }
        }
        .pagination.up {
            top: -75px;
        }
    }
    @media screen and (max-width: 991px) {
        .right {
            width: auto;
            margin: 0 -10px;
            .pagination {
                text-align: center;
                margin-left: 10px;
            }
        }
        .cat__col {
            width: calc(33.33% - 20px);
            margin: 0 10px 20px;
        }
        .pagination.up {
            display: none;
        }
    }
    @media screen and (max-width: 680px) {
        .cat__col {
            width: calc(50% - 20px);
            .cart {
                bottom: 20px;
            }
        }
        &.cat--search {
            .cat__col {
                width: calc(50% - 30px);
            }
        }
    }
    @media screen and (max-width: 480px) {
        .right {
            margin: 0;
        }
        .cat__col {
            width: 100%;
            margin: 0 0 20px;
        }
        &.cat--search {
            margin: 0;
            .cat__col {
                min-height: 0px;
                width: 100%;
                margin: 0 0 20px;
            }
        }
    }
}
.cat--slider {
    width: calc(100% + 30px);
    margin: 0 -15px;
    .slick-slide {
        outline: none;
        overflow: hidden;
        margin: 0 15px;
        .cat__col h6 {
            min-height: 60px;
        }
    }
    .cat__col {
        width: 100%;
        margin: 0;
    }
}
.arrow {
    .slick-arrow {
        width: 35px;
        height: 35px;
        background-color: #fff;
        transform: translate(0,0);
        top: -80px;
        z-index: 1;
        transition: 300ms;
        &:before {
            display: none;
        }
        &:after {
            content: '';
            width: 100%;
            height: 100%;
            background: url(../img/arrow.png) no-repeat center center;
            position: absolute;
            top: 0;
            left: 0;
        }
        &.slick-next {
            right: 15px;
            &:after {
                transform: rotate(180deg);
            }
        }
        &.slick-prev {
            left: auto;
            right: 62px;
        }
        &:hover {
            opacity: 0.7;
        }
    }
    @media screen and (max-width: 991px) {
        .slick-arrow {
            top: -60px;
        }
    }
    @media screen and (max-width: 768px) {
        .slick-arrow {
            top: -45px;
        }
    }
    @media screen and (max-width: 580px) {
        padding: 0 30px;
        .slick-arrow {
            width: 30px;
            height: 30px;
            top: 50%;
            box-shadow: 0px 0px 5px rgba(0,0,0,0.2);
            &.slick-prev {
                left: 10px;
            }
            &.slick-next {
                right: 10px;
            }
        }
    }
    @media screen and (max-width: 380px) {
        padding: 0 25px;
        .slick-arrow {
            &.slick-prev {
                left: 5px;
            }
            &.slick-next {
                right: 5px;
            }
        }
    }
}
.stiker {
    position: absolute;
    top: 25px;
    left: 0;
    line-height: 1;
    span, a {
        display: block;
        width: 100%;
        font-family: $bold;
        font-size: 10px;
        text-transform: uppercase;
        text-align: center;
        color: #fff;
        padding: 10px 15px;
        margin-bottom: 6px;
        &.green {
            background-color: #1c7244;
        }
        &.gray {
            background-color: #444341;
        }
    }
}
.tg {
    @include inline-block;
    height: 12px;
    font-family: $bold;
    font-size: 16px;
    text-transform: lowercase!important;
    color: #2b2a29;
    padding-top: 3px;
    position: relative;
    line-height: 7px;
    margin: 3px 0 0 3px;
    &:after {
        content: '';
        background-color: #2b2a29;
        height: 2px;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
    }
}

.price {
    font-family: $bold;
    color: #2b2a29;
}

.sidebar--btn {
    display: none;
}
.sidebar {
    width: 260px;
    background-color: #ffffff;
    padding: 15px 15px 55px;
    ul {
        @include ul-default;
        li {
            position: relative;
            &.active > a {
                color: #009846;
            }
            .sidebar__sub--btn {
                font-family: 'fontAwesome';
                font-size: 14px;
                position: absolute;
                top: 14px;
                right: 15px;
                padding-right: 0;
                &:after {
                    content: '\f107';
                }
            }
            &.open {
                .sidebar__sub--btn {
                    transform: rotate(-180deg);
                }
            }
        }
    }
    > ul {
        > li {
            padding: 12px 15px;
            border-bottom: 1px solid #d5d4d4;
            > a {
                padding-right: 15px;
            }
        }
        ul {
            display: none;
            padding-top: 5px;
            li {
                padding-left: 18px;
            }
        }
    }
    @media screen and (max-width: 991px) {
        width: 100%;
        padding-bottom: 25px;
        order: 2;
        margin-top: 25px;
        ul li .sidebar__sub--btn  {
            top: 15px;
        }
    }
}

.cat--unit {
    background-color: #fff;
    padding: 40px;
    .left, .right {
        @include inline-block;
        width: calc(50% - 15px);
        position: relative;
        margin-bottom: 30px;
    }
    .left {
        .stiker {
            left: auto;
            right: 0;
            top: 40px;
            z-index: 1;
        }
    }
    .right {
        margin-left: 25px;
    }
    h2, h1 {
        padding-top: 0;
        &:after {
            display: none;
        }
    }
    h1 {
        font-size: 36px;
        margin-bottom: 25px;
    }
    h3 {
        margin-bottom: 15px;
    }
    .cat--unit__price {
        @include inline-block;
        height: 40px;
        background-color: #e3e3e3;
        padding: 6px 10px 6px 15px;
        .price {
            @include inline-block;
            min-width: 135px;
            font-family: $bold;
            font-size: 24px;
            color: #2b2a29;
            .tg {
                font-size: 24px;
                padding-top: 4px;
                margin-top: 5px;
                &:after {
                    height: 3px;
                }
            }
        }
        .old--price {
            @include inline-block;
            min-width: 85px;
            font-size: 14px;
            color: #626160;
            text-decoration: line-through;
            margin: 6px 0 0 5px;
            .tg {
                font-family: $regular;
                font-size: 14px;
                padding-top: 1px;
                margin-top: 3px;
                &:after {
                    height: 1px;
                    background-color: #626160;
                }
            }
        }
        .percent {
            @include inline-block;
            position: relative;
            background-color: #009846; 
            font-size: 12px;
            color: #fff;
            padding: 2px 5px;
            line-height: 1;
            margin: 6px 0 0 5px;
            &:after {
                content: '';
                width: 0;
                height: 0;
                position: absolute;
                top: 50%;
                left: -4px;
                border-top: 4px solid transparent;
                border-bottom: 4px solid transparent;
                border-right: 4px solid #009846;
                transform: translateY(-50%);
            }
        }
    }
    .btn {
        @include inline-block;
        width: 200px!important;
        height: 40px;
        padding:  10px 60px 10px 10px!important;
        float: right;
    }
    .cat--unit__table {
        margin: 20px 0;
        tr {
            td {
                font-size: 14px;
                padding: 4px 0;
                position: relative;
                strong {
                    font-weight: normal;
                    background-color: #fff;
                    color: #818180;
                    padding: 1px;
                    position: relative;
                    z-index: 1;
                }
                &:nth-of-type(1) {
                    padding-right: 25px;
                    &:after {
                        content: '';
                        height: 1px;
                        position: absolute;
                        left: 0;
                        bottom: 7px;
                        right: 0;
                        border-bottom: 1px dashed #414141;
                    }
                }
            }
        }
    }
    .cat--unit__callback {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 14px;
        padding: 15px 30px;
        border: 1px solid #e3e3e3;
        p {
            padding-right: 15px;
            margin: 0;
        }
        ul {
            @include ul-default;
            width: 170px;
            li {
                a {
                    font-family: $bold;
                    font-size: 18px;
                    color: #009846;
                }
            }
        }
    }
    .advantages {
        width: 100%;
        margin: 30px 0 20px;
        .advantages__col {
            border: 1px solid #e3e3e3;
        }
    }
    @media screen and (max-width: 1199px) {
        .cat--unit__price {
            width: 100%;
        }
        .btn {
            float: none;
            margin: 15px 0 0;
        }
        .cat--unit__callback {
            padding: 12px 15px;
        }
    }
    @media screen and (max-width: 991px) {
        padding: 20px;
        .left, .right {
            width: 100%;
        }
        .left {
            .stiker {
                top: 20px;
            }
        }
        .right {
            margin-left: 0;
            margin-bottom: 20px;
        }
        .cat--unit__callback {
            width: 470px;
        }
        .btn {
            padding-right: 40px!important;
        }
    }
    @media screen and (max-width: 768px) {
        h1 {
            font-size: 24px;
        }
    }
    @media screen and (max-width: 620px) {
        .advantages {
            padding: 0;
            margin: 0;
        }
    }
    @media screen and (max-width: 540px) {
        .cat--unit__callback {
            display: block;
            width: 100%;
            p {
                padding: 0;
                margin-bottom: 10px;
            }
        }
    }
    @media screen and (max-width: 480px) {
        .cat--unit__price {
            padding: 6px 10px 6px 10px;
            .price {
                font-size: 20px;
                min-width: 0px;
                .tg {
                    font-size: 16px;
                    padding-top: 3px;
                    &:after {
                        height: 2px;
                    }
                }
            }
            .old--price {
                min-width: 0px;
            }
        }
    }
}

.cat--unit__for {
    width: 100%;
    .slick-slide {
        outline: none;
        height: 400px;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center center;
        }
    }
    @media screen and (max-width: 580px) {
        .slick-slide {
            height: 350px;
        }
    }
    @media screen and (max-width: 480px) {
        .slick-slide {
            height: 300px;
        }
    }
    @media screen and (max-width: 420px) {
        .slick-slide {
            height: 275px;
        }
    }
}
.cat--unit__nav {
    width: 100px;
    position: absolute;
    top: 50%;
    left: -15px;
    transform: translateY(-50%);
    .slick-slide {
        height: 70px;
        margin: 5px 0;
        border: 2px solid #e3e3e3;
        transition: 300ms;
        cursor: pointer;
        outline: none;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center center;
        }
        &:hover, &.slick-current {
            border: 2px solid #009846;
        }
    }
    &.arrow {
        .slick-arrow {
            top: 0;
            left: 0;
            right: 0;
            margin: 0 auto;
            &.slick-prev {
                top: -45px;
                transform: rotate(90deg);
            }
            &.slick-next {
                top: auto;
                bottom: -45px;
                transform: rotate(90deg);
            }
        }
    }
    @media screen and (max-width: 580px) {
        left: -10px;
        &.arrow {
            padding: 0;
        }
    }
    @media screen and (max-width: 480px) {
        width: 60px;
        .slick-slide {
            height: 50px;
        }
        &.arrow {
            .slick-arrow {
                &.slick-prev {
                    top: -35px;
                }
                &.slick-next {
                    bottom: -35px;
                }
            }
        }
    }
}
.similar {
    display: block;
}
/* page catalog */

/* page pagination */
.pagination {
    ul {
        @include ul-default;
        li {
            @include inline-block;
            margin: 0 15px 5px 0px;
            a {
                display: flex;
                width: 35px;
                height: 35px;
                font-family: $bold;
                font-size: 16px;
                flex-direction: column;
                justify-content: center;
                background-color: #fff;
                text-align: center;
                position: relative;
                &:hover {
                    box-shadow: 0px 0px 10px rgba(0,0,0,0.2);
                }
            }
            &.active a {
                color: #009846;
                box-shadow: 0px 0px 10px rgba(0,0,0,0.2);
            }
            &.prev, &.next {
                a {
                    &:after {
                        content: '';
                        width: 10px;
                        height: 20px;
                        background: url(../img/arrow.png) no-repeat center center;
                        background-size: contain;
                        position: absolute;
                        top: 50%;
                        left: 0;
                        right: 0;
                        margin: 0 auto;
                        transform: translateY(-50%);
                    }
                }
            }
            &.next a {
                &:after {
                    transform: rotate(-180deg) translateY(50%);
                }
            }
        }
    }
    &.up {
        width: auto!important;
        position: absolute;
        top: -82px;
        right: 0;
        ul li {
            margin-right: 0;
            margin-left: 15px;
        }
    }
    @media screen and (max-width: 991px) {
        ul li {
            margin-right: 10px;
        }
    }
}
/* page pagination */

/* page cart */
.cart--table {
    width: 100%;
    background-color: #ffffff;
    border-collapse: separate;
    padding: 10px 40px;
    tr {
        position: relative;
        td {
            padding: 30px 15px;
            border-bottom: 1px solid #d5d4d4;
            &.cart--table__good {
                width: 550px;
                padding-left: 0px;
                .cart--table__good--img {
                    @include inline-block;
                    width: 190px;
                    height: 125px;
                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        object-position: center center;
                    }
                }
                .cart--table__good--info {
                    display: inline-block;
                    width: calc(100% - 195px);
                    flex-direction: column;
                    font-size: 14px;
                    color: #2b2a29;
                    padding-left: 30px;
                    p {
                        margin-bottom: 0;
                    }
                    ul {
                        @include ul-default;
                        li {
                            margin: 10px 0;
                            strong {
                                font-weight: normal;
                                color: #818180;
                            }
                        }
                    }
                }
            }
            &.cart--table__price {
                .price {
                    font-size: 16px;
                    color: #2b2a29;
                }
            }
            &.cart--table__close {
                padding-right: 0px;
            }
        }
        &:last-child {
            td {
                border-bottom: none;
            }
        }
    }
    @media screen and (max-width: 1199px) {
        tr {
            td {
                &.cart--table__good {
                    width: 450px;
                    .cart--table__good--info {
                        padding-left: 15px;
                    }
                }
            }
        }
    }
    @media screen and (max-width: 991px) {
        padding: 10px 20px;
        tr {
            td {
                padding: 15px;
                &.cart--table__good {
                    width: 340px;
                    .cart--table__good--img {
                        width: 135px;
                        height: 100px;
                    }
                    .cart--table__good--info {
                        h6 {
                            margin-top: 0;
                        }
                        ul li {
                            margin: 5px 0;
                        }
                    }
                }
            }
        }
    }
    @media screen and (max-width: 768px) {
        tr {
            display: block;
            position: relative;
            padding-bottom: 60px;
            padding-right: 15px;
            border-bottom: 1px solid #d5d4d4;
            &:last-of-type {
                border-bottom: none;
            }
            td {
                border: none;
                padding: 0;
                &.cart--table__good {
                    display: flex;
                    width: 100%;
                    padding-top: 15px;
                }
                &.cart--table__price {
                    display: none;
                }
                &.cart--table__total--price {
                    position: absolute;
                    bottom: 20px;
                    left: 100px;
                }
                &.cart--table__quantity {
                    position: absolute;
                    left: 0;
                    bottom: 10px;
                }
                &.cart--table__close {
                    position: absolute;
                    top: 10px;
                    right: 0;
                }
            }
        }
    }
    @media screen and (max-width: 480px) {
        tr {
            td {
                &.cart--table__good {
                    .cart--table__good--img {
                        width: 85px;
                        height: 70px;
                    }
                    .cart--table__good--info {
                        width: calc(100% - 85px);
                    }
                }
            }
        }
    }
}
.quantity {
    width: 60px;
    height: 40px;
    border: 1px solid #d5d4d4;
    position: relative;
    overflow: hidden;
    input[type="text"] {
        min-height: 38px;
        width: 38px;
        text-align: center;
        padding: 8px 5px;
        outline: none;
        border: none;
        margin: 0;
    }
    a {
        display: block;
        width: 20px;
        height: calc(50% + 2px);
        text-align: center;
        border: 1px solid #d5d4d4;
        border-left: 1px solid #b3b2b2;
        position: absolute;
        top: -1px;
        right: -1px;
        &.minus {
            top: auto;
            bottom: -1px;
        }
    }
}
.total {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    align-items: center;
    padding: 50px 0 80px 0;
    span {
        font-family: $bold;
        font-size: 18px;
        .price {
            font-size: 24px;
            .tg {
                font-size: 24px;
                padding-top: 4px;
                margin-top: 5px;
                &:after {
                    height: 3px;
                }
            }
        }
    }
    .btn {
        padding: 12px 70px 11px!important;
        margin-left: 50px;
    }
    @media screen and (max-width: 991px) {
        padding: 30px 0 40px;
        span {
            .price {
                font-size: 20px;
                .tg {
                    font-size: 20px;
                    padding-top: 3px;
                    &:after {
                        height: 2px;
                    }
                }
            }
        }
        .btn {
            padding: 12px 30px 11px!important;
        }
    }
    @media screen and (max-width: 480px) {
        justify-content: center;
        flex-direction: column;
        padding: 20px 0 30px;
        .btn {
            margin: 15px auto 0;
        }
    }
}
/* page cart */

/* page formalization */
.formalization {
    display: block;
    background-color: #fff;
    padding: 10px 40px;
    margin-bottom: 80px;
    .formalization__row {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: flex-start;
        padding: 40px 0;
        border-bottom: 1px solid #d5d4d4;
        &.order {
            border-bottom: none;
        }
        &.info {
            .col {
                width: 490px;
                &:last-of-type {
                    width: 510px;
                    justify-content: space-between;
                    p {
                        margin: 20px 0 0;
                    }
                }
            }
        }
        &.delivery {
            justify-content: flex-start;
            .check {
                max-width: 350px;
                &:first-of-type {
                    margin-right: 140px;
                }
            }
        }
        &.order {
            .col {
                max-width: 49%;
            }
        }
        .col {
            display: flex;
            flex-wrap: wrap;
            input:last-of-type {
                margin-bottom: 0;
            }
        }
    }
    h6 {
        width: 100%;
        margin: 0 0 30px;
    }
    input[type="text"],
    input[type="email"] {
        width: 100%;
    }
    input[type="radio"],
    input[type="checkbox"] {
        display: none;
    }
    table {
        width: 100%;
        tr td {
            padding: 10px 10px 10px 0;
        }
    }
    .total {
        display: flex;
        width: 515px;
        justify-content: flex-start;
        background-color: #e3e3e3;
        padding: 40px 30px;
        span {
            text-transform: uppercase;
        }
        .btn {
            margin-left: 0;
            margin-top: 15px;
        }
    }
    @media screen and (max-width: 1199px) {
        padding: 15px;
        .formalization__row {
            padding: 20px 0;
            .col {
                width: 49%;
            }
            &.info {
                .col {
                    width: 49%;
                    &:last-of-type {
                        width: 49%;
                    }
                }
            }
        }
        h6 {
            margin-bottom: 20px;
        }
    }
    @media screen and (max-width: 991px) {
        padding: 5px 15px;
        margin-bottom: 50px;
        .formalization__row {
            &.order {
                .col {
                    width: 100%;
                }
            }
            &.delivery .check {
                &:first-of-type {
                    margin-right: 15px;
                    margin-bottom: 10px;
                }
            }
        }
        .total {
            width: 100%;
            justify-content: space-between;
            padding: 15px;
            margin-top: 15px;
            .btn {
                margin-top: 15px;
            }
        }
    }
    @media screen and (max-width: 768px) {
        margin-bottom: 30px;
    }
    @media screen and (max-width: 680px) {
        .formalization__row {
            .col {
                width: 100%!important;
                margin-bottom: 15px;
            }
            &.order {
                .col {
                    width: 100%;
                    max-width: 100%;
                }
            }
        }
    }
    @media screen and (max-width: 540px) {
        .total {
            flex-direction: column;
            justify-content: center;
            .btn {
                margin: 15px auto 0;
            }
        }
    }
    @media screen and (max-width: 480px) {
        .formalization__row {
            &.order {
                table tr {
                    td {
                        display: block;
                        padding: 5px 0px;
                    }
                }
            }
        }
    }
}
.check {
    position: relative;
    font-weight: normal;
    padding-left: 25px;
    cursor: pointer;
    &:after {
        content: '';
        width: 10px;
        height: 10px;
        position: absolute;
        left: 0;
        top: 4px;
        border: 1px solid #9f9f9e;
        background-color: transparent;
        transition: 300ms;
    }
    span {
        display: block;
        font-size: 14px;
        color: #818181;
    }
    &:hover {
        &:after {
            border: 1px solid #009846;
            background-color: #009846;
        }
    }
}
input[type="radio"]:checked + .check:after,
input[type="checkbox"]:checked + .check:after {
    border: 1px solid #009846;
    background-color: #009846;
}
/* page formalization */

/* page contacts */
.cts {
    ul {
        @include ul-default;
    }
    .cts__block {
        margin-bottom: 20px;
        .cts__block--col {
            @include inline-block;
            width: 27%;
            padding-right: 15px;
            &:nth-of-type(1) {
                width: 45%;
            }
            ul {
                li {
                    margin-bottom: 20px;
                    a {
                        display: table;
                    }
                }
            }
            .col--phone:after {
                top: 1px;
            }
            .socials {
                li {
                    @include inline-block;
                    margin: 0 13px 0 0;
                    a {
                        display: flex;
                        width: 30px;
                        height: 30px;
                        font-size: 18px;
                        align-items: center;
                        justify-content: center;
                        background-color: #dadada;
                        .fa-paper-plane {
                            font-size: 14px;
                        }
                    }
                }
            }
            h6 {
                text-transform: inherit;
                margin-top: 0;
                margin-bottom: 20px;
            }
            .show--to--map {
                font-size: 14px;
                color: #626160;
                text-decoration: underline;
                &:hover {
                    text-decoration: none;
                }
            }
        }
        .cts__row {
            padding: 18px 0;
            border-bottom: 1px solid #bebebe;
            &:nth-of-type(1) {
                padding-top: 0;
            }
            .cts__block--col {
                ul li {
                    margin-bottom: 0;
                }
            }
        }
    }
    .cts__form {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        background-color: #fff;
        padding: 45px 40px;
        margin: 50px 0;
        .left {
            width: 350px;
            padding-right: 20px;
            p {
                color: #aaaaaa;
                strong {
                    color: #1b1414;
                }
            }
        }
        .right {
            width: calc(100% - 350px);
        }
        .g-recaptcha {
            float: left;
        }
        .btn {
            float: right;
            margin-top: 30px;
        }
    }
    .map {
        height: 380px;
        margin-bottom: 35px;
        > ymaps, .ymaps-2-1-72-map {
            height: 100%!important;
        }
    }
    @media screen and (max-width: 991px) {
        .map {
            height: 280px;
        }
        h3 {
            margin-bottom: 10px;
        }
        .cts__block {
            .cts__block--col {
                width: 32%!important;
            }
        }
        .cts__form {
            flex-direction: column;
            padding: 20px;
            .left, .right {
                width: 100%;
                padding-right: 0;
            }
        }
    }
    @media screen and (max-width: 680px) {
        .cts__block {
            .cts__block--col {
                width: 49%!important;
            }
        }
    }
    @media screen and (max-width: 510px) {
        .cts__form {
            .g-recaptcha {
                float: right;
            }
            .btn {
                margin-top: 15px;
            }
        }
    }
    @media screen and (max-width: 480px) {
        .cts__block {
            .cts__block--col {
                width: 100%!important;
                ul li {
                    margin-bottom: 15px!important;
                }
            }
        }
    }
    @media screen and (max-width: 380px) {
        .cts__form {
            .g-recaptcha {
                transform: scale(0.82);
                transform-origin: 100% 50%;
            }
        }
    }
}
/* page contacts */

/* modal */
.modal {
    .modal-dialog {
        top: 45%;
        margin: 0 auto;
        transform: translateY(-50%)!important;
    }
    .modal-content {
        width: auto;
        max-width: 420px;
        border-radius: 0px;
        margin: 0 auto;
        h4 {
            font-family: $bold;
            font-size: 18px;
            text-align: center;
            text-transform: uppercase;
            padding: 0 15px;
            margin: 0;
        }
        .close {
            width: 25px;
            height: 25px;
            position: absolute;
            right: 8px;
            top: 10px;
            opacity: 1;
            outline: none;
            &:before, &:after {
                content: '';
                width: 25px;
                height: 2px;
                background-color: #c74a34;
                position: absolute;
                top: 50%;
                left: 0;
                right: 0;
                margin: -1px auto 0;
                transition: 300ms;
            }
            &:before {
                transform: rotate(45deg);
            }
            &:after {
                transform: rotate(-45deg);
            }
        }
    }
    form {
        display: block;
        padding-bottom: 140px;
        margin-top: 25px;
        .g-recaptcha {
            float: right;
        }
        .btn {
            float: right;
            margin: 15px 0 0 0;
        }
    }
    @media screen and (max-width: 767px) {
        .modal-content {
            h4 {
                font-size: 16px;
            }
        }
    }
    @media screen and (max-width: 420px) {
        .modal-dialog {
            margin: 0 10px;
            h4 {
                font-size: 16px;
            }
        }
    }
    @media screen and (max-width: 380px) {
        .modal-content {
            form {
                .g-recaptcha {
                    transform: scale(0.85);
                    transform-origin: 100% 50%;
                }
            }
        }
    }
}

.ymaps-2-1-65-balloon__close+.ymaps-2-1-65-balloon__content {
    padding: 25px!important;
    margin: 0!important;    
    background-color: #000;
}
.ymaps-2-1-65-balloon__layout {
    overflow: visible!important;
}
.ymaps-2-1-68-balloon__close-button{
    width: 20px;
    height: 20px;
    position: absolute!important;
    margin: 0;
    top: -5px;
    right: -6px!important;
    border-radius: 100%;
    opacity: 1!important;
    transition: 300ms;
    &:before, &:after {
        content: '';
        height: 2px;
        width: 60%;
        background-color: #af8a52;
        position: absolute;
        top: 50%;
        left: 0;
        right: 0;
        margin: -1.5px auto;
        transform: rotate(45deg);
        z-index: 1;
    }
    &:after {
        transform: rotate(-45deg);
    }
    &:hover {
        opacity: 0.7;
    }
}
.ymaps-2-1-68-balloon__close-button {
    width: 25px!important;
    height: 25px!important;
}
.ymaps-2-1-68-balloon__content {
    background-color: #000!important;
    padding: 10px 12px!important;
    margin: 0!important;
}
.ymaps-2-1-65-balloon__tail:after {
    background-color: #000!important;
}
.leaflet-popup-content-wrapper {
    background-color: #000!important;
}
.leaflet-container a.leaflet-popup-close-button {
    top: -6px!important;
    right: -5px!important;
}
.leaflet-map-pane .leaflet-popup-tip-container_svg {
    fill: rgba(0,0,0,1)!important;
}
.ymaps-2-1-68-balloon__tail:after {
    background-color: #000!important;
}
.map__block {
    width: 207px;
    font-size: 16px;
    .map__block__img {
        border: 1px solid #af8a52;
    }
    img {
        display: block;
        width: 100%;
    }
    p {
        margin: 5px 0!important;
    }
    a {
        display: block;
        font-size: 16px;
        color: #009846;
        text-decoration: underline;
        margin: 5px 0;
        &:hover {
            text-decoration: none;
        }
    }
}

/* modal */

/* page content */